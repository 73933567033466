const initialState = {
  cityName: "FR-paris",
  tutionFeeRange: "",
  maxPrice: "",
  minPrice: "",
  moveInDate: "",
  moveOutDate: "",
  locale: "",
  propertyFeatures: [],
  bedType: [],
  housingType: [],
  rentType: [],
  pageNumber: "1",
  
  detail: false,
  cityList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "RESET":
      return {
        ...state,
        cityName: "FR-paris",
        tutionFeeRange: "",
        maxPrice: "",
        minPrice: "",
        moveInDate: "",
        moveOutDate: "",
        propertyFeatures: [],
        bedType: [],
        housingType: [],
        rentType: [],
        pageNumber: "1",
        detail: false,
      }
    case "TOGGLE_DETAIL_PAGE":
      return { ...state, detail: action.detail }
    case "TOGGLE_CITYNAME":
      return { ...state, cityName: action.cityName }
    case "TOGGLE_TUTION_FEE_RANGE":
      return { ...state, tutionFeeRange: action.tutionFeeRange }
    case "TOGGLE_MAX_PRICE":
      return { ...state, maxPrice: action.maxPrice }
    case "TOGGLE_MIN_PRICE":
      return { ...state, minPrice: action.minPrice }
    case "TOGGLE_MOVE_IN_DATE":
      return { ...state, moveInDate: action.moveInDate }
    case "TOGGLE_MOVE_OUT_DATE":
      return { ...state, moveOutDate: action.moveOutDate }
    case "TOGGLE_LOCALE":
      return { ...state, locale: action.locale }
    case "TOGGLE_PROPERTY_FEATURE":
      return {
        ...state,
        propertyFeatures: [...state.propertyFeatures, action.newItem],
      }
    case "TOGGLE_REPLACE_PROPERTY_FEATURE":
      return {
        ...state,
        propertyFeatures: action.Item,
      }
    case "TOGGLE_BED_TYPE":
      return {
        ...state,
        bedType: [...state.bedType, action.bedItem],
      }
    case "TOGGLE_REPLACE_BED_TYPE":
      return {
        ...state,
        bedType: action.bedItem,
      }
    case "TOGGLE_HOUSING_TYPE":
      return {
        ...state,
        housingType: [...state.housingType, action.housingItem],
      }
    case "TOGGLE_REPLACE_HOUSING_TYPE":
      return {
        ...state,
        housingType: action.housingItem,
      }
    case "TOGGLE_RENT_TYPE":
      return {
        ...state,
        rentType: [...state.rentType, action.rentItem],
      }
    case "TOGGLE_REPLACE_RENT_TYPE":
      return {
        ...state,
        rentType: action.rentItem,
      }
    case "TOGGLE_CITY_LIST_TYPE":
      return {
        ...state,
        cityList: [...state.cityList, action.city],
      }
    case "TOGGLE_PAGE_NUMBER":
      return { ...state, pageNumber: action.pageNumber }

    default:
      return state
  }
}
