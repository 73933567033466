// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-auth-facebook-callback-index-js": () => import("./../../../src/pages/auth/facebook/callback/index.js" /* webpackChunkName: "component---src-pages-auth-facebook-callback-index-js" */),
  "component---src-pages-auth-google-callback-index-js": () => import("./../../../src/pages/auth/google/callback/index.js" /* webpackChunkName: "component---src-pages-auth-google-callback-index-js" */),
  "component---src-pages-auth-linkedin-callback-index-js": () => import("./../../../src/pages/auth/linkedin/callback/index.js" /* webpackChunkName: "component---src-pages-auth-linkedin-callback-index-js" */),
  "component---src-pages-blog-detail-js": () => import("./../../../src/pages/blog-detail.js" /* webpackChunkName: "component---src-pages-blog-detail-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-topic-js": () => import("./../../../src/pages/blog/topic.js" /* webpackChunkName: "component---src-pages-blog-topic-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-edit-profile-js": () => import("./../../../src/pages/edit-profile.js" /* webpackChunkName: "component---src-pages-edit-profile-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-house-js": () => import("./../../../src/pages/house.js" /* webpackChunkName: "component---src-pages-house-js" */),
  "component---src-pages-housing-js": () => import("./../../../src/pages/housing.js" /* webpackChunkName: "component---src-pages-housing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-personal-profile-js": () => import("./../../../src/pages/personal-profile.js" /* webpackChunkName: "component---src-pages-personal-profile-js" */),
  "component---src-pages-pricing-confirmation-failure-js": () => import("./../../../src/pages/pricing/confirmation/failure.js" /* webpackChunkName: "component---src-pages-pricing-confirmation-failure-js" */),
  "component---src-pages-pricing-confirmation-success-js": () => import("./../../../src/pages/pricing/confirmation/success.js" /* webpackChunkName: "component---src-pages-pricing-confirmation-success-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-pricing-summary-js": () => import("./../../../src/pages/pricing/summary.js" /* webpackChunkName: "component---src-pages-pricing-summary-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-request-callback-js": () => import("./../../../src/pages/request-callback.js" /* webpackChunkName: "component---src-pages-request-callback-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-service-details-page-js": () => import("./../../../src/pages/services/serviceDetailsPage.js" /* webpackChunkName: "component---src-pages-services-service-details-page-js" */),
  "component---src-pages-study-in-france-detail-js": () => import("./../../../src/pages/study-in-france-detail.js" /* webpackChunkName: "component---src-pages-study-in-france-detail-js" */),
  "component---src-pages-study-in-france-js": () => import("./../../../src/pages/study-in-france.js" /* webpackChunkName: "component---src-pages-study-in-france-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-results-js": () => import("./../../../src/pages/tools/results.js" /* webpackChunkName: "component---src-pages-tools-results-js" */),
  "component---src-pages-universities-js": () => import("./../../../src/pages/universities.js" /* webpackChunkName: "component---src-pages-universities-js" */),
  "component---src-pages-university-detail-js": () => import("./../../../src/pages/university-detail.js" /* webpackChunkName: "component---src-pages-university-detail-js" */),
  "component---src-pages-user-profile-js": () => import("./../../../src/pages/user-profile.js" /* webpackChunkName: "component---src-pages-user-profile-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

